@import "src/styles/variables";

.generic-search {
  .input-label {
    background-color: #fff;
    padding-left: 8px;
    padding-right: 8px;
    left: -4px;
  }

  .react-select-container {
    .react-select__control {
      cursor: text;
      background-color: transparent;

      &:hover {
        border-color: $dark;
      }

      .react-select__value-container {
        padding-left: 11px;

        .react-select__input {
          padding-top: 7px;
          padding-bottom: 7px;
        }
      }

      .react-select__indicators {
        .react-select__indicator-separator {
          display: none;
        }
      }
    }
    
    &.error .react-select__control {
      border-color: #f44336;
    }
  }
}