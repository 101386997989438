@import "src/styles/variables";

$header_height: 4rem;
$header-bg: $pattensBlue !default;
$header-color: $regalBlue !default;

$logo_spacing: 0.625rem;
$logo_max_height: $header_height - 2 * $logo_spacing;
$logo_max_width: $logo_max_height * 1600 / 336;

#root {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 0;
  display: flex;
}

#header {
  height: $header_height;
  width: 100%;
  z-index: $zindex-fixed !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 8px;
  border: 0;
  text-align: center;
  background-color: $header-bg;
  position: relative;

  .header-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: inherit;
    color: $header-color;
    margin: 0 8px;

    > * + * {
      margin-left: 12px;
    }

    &.actions-left {

    }

    &.actions-right {
      .action-icon-button {
        font-size: 32px;
        line-height: 1rem;
        padding: 7px;

        &.disabled {
          pointer-events: none;
          color: $btn-link-disabled-color;
        }
      }
    }
  }

  .header-center {
    flex: 1;
    z-index: inherit;
    display: flex;

    .header-logo-button {
      z-index: inherit;
      padding: $logo_spacing 0 $logo_spacing $logo_spacing;

      .header-logo {
        height: auto;
        max-height: $logo_max_height;
        width: 100%;
        max-width: $logo_max_width;
      }
    }
  }
}

#content {
  height: 100vh;
  width: 100vw;
  z-index: 1;
  background-color: $body-bg;
  overflow-y: auto;
  &.noUser {
    height: 100vh; 
    background: linear-gradient(90deg, $ghostWhite 0%, $lavender 100%);
    background-size: cover;
    background-position: right;
  }
  &.withUser {
    padding: 5.5rem 1.5rem 1.5rem 1.5rem;
  }
}
