@import "src/styles/variables";

.image-control {
  position: relative;
  color: #4B5866;
    
  .image-wrap {
    width: 100%;
    border-radius: 4px;
    background-color: #676E7A;
    display: flex;
    justify-content: center;
    align-items: center;

    > img {
      width: 100%;
      height: auto;
    }
  }

  .trigger {
    font-size: 0.75rem;
  }
}
