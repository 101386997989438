.upload-preview-wrap {
  display: flex;
  justify-content: center;

  .upload-preview {
    max-width: 250px;
    max-height:250px;
    height: auto;
    width: auto; 
  }
}
