@import "src/styles/variables";

.avatar {
  height: 4rem !important;
  width: 4rem !important;

  &.avatar-xs {
    height: 2rem !important;
    width: 2rem !important;
  }

  &.avatar-sm {
    height: 3rem !important;
    width: 3rem !important;
  }

  &.avatar-lg {
    height: 5rem !important;
    width: 5rem !important;
  }

  &.avatar-xl {
    height: 6rem !important;
    width: 6rem !important;

    @include media-breakpoint-down(lg) {
      height: 5rem !important;
      width: 5rem !important;
    }
  }
}
