@import "src/styles/variables";

$icon-size: $spacer*3;

.cart-item {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
  }
  
  .cart-item-col {
    margin-left: $spacer;

    @include media-breakpoint-up(xl) {
      margin-left: $spacer*2;
    }
  }

  .discount {
    color: $danger;
  }

  .discount-small {
    font-size: 0.8rem;
  }

  .xs-icon-button {
    width: 19px;
    height: 19px;
    min-height: 19px;
    box-shadow: none;

    svg {
      width: 15px;
      height: 15px;
    }
  }

  .image {
    order: 1;
    margin-left: $spacer;
    min-height: $icon-size;
    height: $icon-size;
    min-width: $icon-size;
    width: $icon-size;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    
    img {
      height: 100%;
      width: 100%;
    }

    .mp-icon-wrapper {
      font-size: $icon-size;
      line-height: 1rem;
    }
  }

  .details {
    order: 2;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .product-name {
      display: block;
      // font-size: 0.875rem;
      // font-weight: 500;
    }
  }

  .breaker {
    order: 3;
    height: $spacer*.5;
    width: 100%;
    content: '';
    display: none;

    @include media-breakpoint-down(md) {
      display: block;
    }
  }

  .pricing {
    order: 4;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: 500;
    // font-size: 0.8125rem;
    min-width: 4rem;
    margin-left: 0;
  }

  .item-ticker {
    order: 5;
    min-width: 6.125rem;
    display: flex;
    
    .item-ticker-inner {
      display: flex;
      align-items: center;
      text-align: center;
    
      .item-quantity {
        font-size: 1.25rem;
        font-weight: 500;
        width: 3rem;
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        input {
          text-align: center;
          padding: 0;
        }

        &:before {
          display: none;
        }
      }
    }
  }

  .total {
    order: 6;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    min-width: 4.5rem;
  }

  .remove {
    order: 7;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}