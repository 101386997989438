@import "../../../styles/variables";

.success-badge {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $success;
  font-size: 3.6rem;
  margin: $spacer * 1.5;
}
