.react-datepicker__day--outside-month {
  color: #fff;
  background-color: #fff;
}

.airbnb-datepicker-container {
  z-index: 10 !important;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 37px !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  white-space: normal !important;
  max-height: calc(100vh - 152px) !important;
  visibility: visible !important;
  background: rgb(255, 255, 255) !important;
  border-width: 0.5px !important;
  border-style: solid !important;
  border-color: rgba(118, 118, 118, 0.28) !important;
  border-image: initial !important;
  border-radius: 12px !important;

  .datepicker-container-inner {
    height: 100% !important;
    max-height: calc(100vh - 240px) !important;
    overflow-x: hidden !important;
    overflow-y: auto !important;
    padding: 8px !important;

    .react-datepicker {
      border: none;

      .react-datepicker__navigation {
        top: 26px;
      }

      .react-datepicker__header {
        background-color: #fff;
        border-bottom: none;
        padding-top: 0;

        .react-datepicker__day-name {
          line-height: 1;
        }

        .react-datepicker__current-month {
          font-weight: 400 !important;
          font-size: 16px !important;
          color: rgb(34, 34, 34) !important;
          padding-top: 22px !important;
          padding-bottom: 22px !important;
        }
      }

      .react-datepicker__month {
        margin-top: 4px;
      }
    }
  }

  .buttons-container {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    padding-left: 14px !important;
    padding-right: 14px !important;
    border-top: 1px solid rgb(235, 235, 235) !important;

    .buttons-container2 {
      -webkit-box-pack: justify !important;
      -webkit-box-align: center !important;
      display: flex !important;
      align-items: center !important;
      justify-content: space-between !important;
    }
  }
}
