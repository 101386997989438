@import "src/styles/variables";

.failure-badge {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $secondary;
  font-size: 3.6rem;
  margin: $spacer * 1.5;
}