@import "src/styles/variables";

.auth-screen {
  height: 100%;
  min-height: calc(500px - #{$header-height});
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .header-logo {
    height: 2.5rem;
    margin: auto;
    display: block;
    margin-bottom: 1rem;
  }

  .form-card {
    width: 480px;
    padding: $spacer*2;
    position: relative;
    margin-bottom: 20vh;

    @media screen and (max-height: 600px) {
      margin-bottom: 10vh;
    }

    @media screen and (max-height: 550px) {
      margin-bottom: 0;
    }

    .form-actions {
      flex-direction: row;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .other-actions {
      text-align: right;
      padding: $spacer*2 $spacer 0 $spacer;

      .link-button {
        text-transform: inherit;
        font-weight: inherit;
        padding: inherit;
        font-size: inherit;

        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}
