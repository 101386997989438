@import "src/styles/variables";

.status-overlay {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // max-height: calc(100vh - #{$header_height});
  z-index: 9;
}
