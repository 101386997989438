.related-checkboxes { 
  margin-top: 16px !important;

  .related-checkbox {
    width: 32px;
    height: 32px;
  }
  
  .form-group {
    margin-left: 24px;
    margin-bottom: 0;
  }
}