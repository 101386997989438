@import "src/styles/variables";

$icon-size: $spacer*3;

.StripeElement {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  padding: 12px 14px;
}

.Clearent {
  .Clearent-content {
    position: relative;
  }
  .Clearent-header {
    margin-bottom: .25rem;
  }
  
  .Clearent-footer {
    border-top: none !important;
  }

  button#Clearent-pay-now {
    background-color: #03a9f4;
    color: #fff;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
    padding: 6px 16px;
    font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Roboto", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    text-transform: uppercase;
    border: 0;

    &:hover {
      background-color: #006db3;
    }

    &[disabled] {
      color: rgba(0, 0, 0, 0.26);
      box-shadow: none;
      background-color: rgba(0, 0, 0, 0.12);
      cursor: default;
      pointer-events: none;
    }
  }
}

iframe#ccNumIframe {
  height: 50px !important;
  display: block;
}

iframe#secCodeIframe {
  height: 50px !important;
  display: block;
}

iframe#expDateIframe {
  height: 50px !important;
  display: block;
}

.cart {
  position: relative;
  height: 100%;
  margin-right: -$spacer;

  .cart-contents {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    .cart-header {
      padding: 0 0 $spacer $spacer;
    }

    .inline-profile {
      padding: $spacer $spacer $spacer $spacer*.5;
      display: flex;
      flex-flow: wrap;
      justify-content: flex-start;
      align-items: center;
      
      .field-wrapper {
        display: inline-flex;
      }

      > div {
        margin-left: $spacer*.5;
      }
    }

    .empty-cart-container {
      padding: $spacer;
    }

    .cart-item {
      position: relative;
      display: flex;
      margin-bottom: 2px;
      padding: $spacer*.5 $spacer;
      border-radius: 0;
      justify-content: space-between;

      @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
      }
      
      .cart-item-col {
        margin-left: $spacer;

        @include media-breakpoint-up(xl) {
          margin-left: $spacer*2;
        }
      }

      .discount {
        color: $danger;
      }

      .discount-small {
        font-size: 0.8rem;
      }

      .xs-icon-button {
        width: 25px;
        height: 25px;
        min-height: 25px;

        svg {
          width: 15px;
          height: 15px;
        }
      }

      .image {
        order: 1;
        margin-left: 0px;
        min-height: $icon-size;
        height: $icon-size;
        min-width: $icon-size;
        width: $icon-size;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        
        img {
          height: 100%;
          width: 100%;
        }

        .mp-icon-wrapper {
          font-size: $icon-size;
          line-height: 1rem;
        }
      }

      .details {
        order: 2;
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        .product-name {
          display: block;
          // font-size: 0.875rem;
          font-weight: 500;
        }
      }

      .breaker {
        order: 3;
        height: $spacer*.5;
        width: 100%;
        content: '';
        display: none;

        @include media-breakpoint-down(sm) {
          display: block;
        }
      }

      .pricing {
        order: 4;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-weight: 500;
        // font-size: 0.8125rem;
        min-width: 4rem;
        margin-left: 0;
      }

      .item-ticker {
        order: 5;
        min-width: 6.125rem;
        display: flex;
        
        .item-ticker-inner {
          display: flex;
          align-items: center;
          text-align: center;
        
          .item-quantity {
            font-size: 1.875rem;
            font-weight: 500;
            width: 3rem;
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }

            input {
              text-align: center;
              padding: 0;
            }

            &:before {
              display: none;
            }
          }
        }
      }

      .total {
        order: 6;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        min-width: 4.5rem;
      }

      &:hover .remove-fab {
        opacity: 1;
      }
    }

    .credit-card-form {
      padding: $spacer;

      > * + * {
        margin-top: $spacer;
      }

      #payment-button-parent {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        width: 100%;
      }
    }

    .transaction-section {
      padding: $spacer;

      > * + * {
        margin-top: $spacer;
      }
    }
  }
}
