@import "src/styles/variables";

.header-text {
	border-bottom: 2px solid;
	padding-bottom: 0.2rem;
	margin-bottom: 1rem !important;
	font-size: 2rem;
	button, .right-button {
		float: right;
	}
}
.header-underline-1x {
	border-bottom: 1px solid;
}
.header-underline-2x {
	border-bottom: 2px solid;
}

.contact-info {
	padding-left: 1em;
	.field {
		margin: 1em 0;
	}
	.name {
		font-size: 1em;
	}
	.value {
		font-size: 1.2em;
	}
}

.Mui-selected {
	// background: rgba(0, 0, 0, 0.14) !important;
	// background: $secondaryBlue !important;
	svg {
		// color: $secondaryBlue !important;
		// color: rgba(0, 0, 0, 0.14) !important;
	}
}

.offer-form {
	padding-top: 1rem;
	padding-left: 3rem;
	padding-right: 3rem;
}

.bold-text {
	font-weight: bold !important;
}