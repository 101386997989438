@import "src/styles/variables";

.Clearent {
  .Clearent-content {
    position: relative;
  }
  .Clearent-header {
    margin-bottom: .25rem;
  }
  
  .Clearent-footer {
    border-top: none !important;
  }

  button#Clearent-pay-now {
    background-color: #03a9f4;
    color: #fff;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
    padding: 6px 16px;
    font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Roboto", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    text-transform: uppercase;
    border: 0;

    &:hover {
      background-color: #006db3;
    }

    &[disabled] {
      color: rgba(0, 0, 0, 0.26);
      box-shadow: none;
      background-color: rgba(0, 0, 0, 0.12);
      cursor: default;
      pointer-events: none;
    }
  }
}

iframe#ccNumIframe {
  height: 50px !important;
  display: block;
}

iframe#secCodeIframe {
  height: 50px !important;
  display: block;
}

iframe#expDateIframe {
  height: 50px !important;
  display: block;
}

#payment-button-parent {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  width: 100%;
  margin-top: 12px;
}

#emergepay-form-container {
  display: none;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  margin-top: 12px;
}
