@import "../../../styles/variables";

.button-success-container {
  display: flex;
  margin-left: $spacer*.5;
  margin-top: $spacer*.125;
  margin-bottom: $spacer*.125;
  width: 24px;
  height: 24px;
  color: $success;
  font-size: 1.8rem;
  align-items: center;
  justify-content: center;
}
