.react-phone-number-input {
  display: flex !important;

  .react-phone-number-input__row {
    flex: 1;
    
    .react-phone-number-input__icon {
      display: flex !important;
    }

    .react-phone-number-input__country-select-arrow {
      margin-top: 0.15em;
    }

    .react-phone-number-input__input {
      border-bottom: none !important;
    }
  }
}
