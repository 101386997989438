@import "src/styles/variables";

.webcam-dialog {
  .image-cropper-wrapper {
    .buttons-container {
      display: flex;
      justify-content: center;
      align-items: center;
      
      button + button {
        margin-left: $spacer*.5;
      }
    }
  }

  .webcam-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 300px;

    .photo-button-wrapper {
      margin-top: $spacer;
    }
  }
}